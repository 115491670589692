import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import "swiper/css";
import "swiper/css/navigation";
import 'react-toastify/dist/ReactToastify.css';

import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

import { AuthProvider } from './Context/useAuth';
import { CurrencyProvider } from './Context/useCurrency';

const client = new ApolloClient({
  uri: 'https://thecricketmohali.avaptech.in/graphql',
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <CurrencyProvider>
      <AuthProvider>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </AuthProvider>
    </CurrencyProvider>
  </BrowserRouter>
);

// Optional: measure performance and report
reportWebVitals();

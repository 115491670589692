import './App.css';
import { lazy, Suspense, useLayoutEffect, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Aos from 'aos';
import 'aos/dist/aos.css';
const Page = lazy(() => import('./Pages/Page'));
const Orders = lazy(() => import('./Layout/Woocommerce/My Account/Orders'));
const Shipping = lazy(() => import('./Layout/Woocommerce/My Account/Shipping'));
const Dashboard = lazy(() => import('./Layout/Woocommerce/My Account/Dashboard'));
const Address = lazy(() => import('./Layout/Woocommerce/My Account/Address'));
const Account = lazy(() => import('./Layout/Woocommerce/My Account/Account'));
const Wishlist = lazy(() => import('./Layout/Woocommerce/My Account/Wishlist'));
const Headers = lazy(() => import('./Layout/Header/Headers'));
const Checkout = lazy(() => import('./Layout/Checkout/Checkout'));
const Loader = lazy(() => import('./libs/Loader'))
const Footer = lazy(() => import('./Layout/Header/Footer'));
const Cart = lazy(() => import('./Layout/Cart/Cart'));
const ProductPage = lazy(() => import('./Layout/Woocommerce/Product/ProductPage/ProductPage'))
const CollectionProduct = lazy(() => import('./Layout/Woocommerce/Product/CollectionProduct/CollectionProduct'));

function App() {

  useEffect(() => {
    Aos.init({
      once:true
    });
  }, []); 
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [pathname]);
  return (
    <Suspense fallback={<Loader />}>
      <Headers />
      <Routes>
        <Route path="/" element={<Page />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/:uri" element={<Page />} />
        <Route path="/my-account/*" element={<Dashboard />} >
          <Route path="" element={<Account />} />
          <Route path="order" element={<Orders />} />
          <Route path="address" element={<Address />} />
          <Route path="address/edit-shipping-address" element={<Shipping />} />
          <Route path="wishlist" element={<Wishlist />} />
        </Route>

        <Route path="/product/:uri" element={<ProductPage />} />
        <Route path="/product-category/:uri" element={<CollectionProduct />} />
        <Route path="/home" element={<Navigate to="/" replace />} />
      </Routes>
      <ToastContainer />
      <Footer />
    </Suspense>
  );
}

export default App;
